import { Bank } from '@/types/api/banks/incom';
import { v4 as uuidv4 } from 'uuid';

export const bankPlaceholder: Readonly<Bank> = {
    id: 0,
    name: '',
    template: {
        id: 0,
        name: '',
        description: '',
        sample: '',
        fields: [],
    },
    authToken: uuidv4(),
};
