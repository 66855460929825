import { EnviromentVariant, Domain } from '@/types/ApiService';

export const DOMAINS: Record<EnviromentVariant, Record<string, Domain>> = {
    // Локальные ветки
    dev: {
        main: {
            secure: true,
            domain: 'test.backend.aspire.kay-com.net',
            prefix: 'api/v1',
        },
        mock: {
            secure: false,
            domain: 'localhost:4000',
            prefix: '',
        },
    },
    // Ветка dev
    test: {
        main: {
            secure: true,
            domain: 'test.backend.aspire.kay-com.net',
            prefix: 'api/v1',
        },
        mock: {
            secure: false,
            domain: 'localhost:4000',
            prefix: '',
        },
    },
    // Препрод ветка prod
    preprod: {
        main: {
            secure: true,
            domain: 'preprod.backend.aspire.kay-com.net',
            prefix: 'api/v1',
        },
        mock: {
            secure: false,
            domain: '',
            prefix: 'api',
        },
    },
    // Прод ветка prod
    prod: {
        main: {
            secure: true,
            domain: 'preprod.backend.aspire.kay-com.net',
            prefix: 'api/v1',
        },
    },
};
