import { FC, Suspense, useEffect } from 'react';
import { useLoaderData, LoaderFunction, defer, Await } from 'react-router-dom';
import { BankTemplate } from '@/types/api/banks/incom';
import { templateService } from '@/services/template.service';
import { CLoader } from '@/components/kit/c-loader';
import { TemplatesTable } from '@/components/pages/templates-page/templates-table';
import { breadcrumbStore } from '@/store/breadcrumb';

export const TemplatesPage: FC = () => {
    const { data } = useLoaderData() as { data: BankTemplate[] };

    useEffect(() => {
        breadcrumbStore.set([{ text: 'Главная', to: '/' }, { text: `Шаблоны импорта` }]);
    }, []);

    return (
        <Suspense fallback={<CLoader />}>
            <Await resolve={data}>{templates => <TemplatesTable templates={templates} />}</Await>
        </Suspense>
    );
};

export const templatesPageLoader: LoaderFunction = async () => {
    return defer({
        data: await templateService.list(),
    });
};
