import { FC } from 'react';
import { Button, Input, Space } from 'antd';
import type { InputRef } from 'antd';

export const BankClientsTableFilterDropdown: FC<{
    selectedKeys: React.Key[];
    dataIndex: number;
    inputRef: React.Ref<InputRef>;
    handleFilter: (id: number, query: string) => void;
    clearFilters: (() => void) | undefined;
    close: () => void;
    selectedKeysChangeCallback: (selectedKeys: React.Key[]) => void;
}> = props => {
    const handleSearch = (selectedKeys: string[], dataIndex: number) => {
        props.handleFilter(dataIndex, selectedKeys[0]);
        props.close();
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        props.handleFilter(props.dataIndex, '');
        props.close();
    };

    return (
        <div
            style={{ padding: 8 }}
            onKeyDown={e => e.stopPropagation()}
        >
            <Input
                ref={props.inputRef}
                placeholder={'Введите текст'}
                value={props.selectedKeys[0]}
                onChange={e => props.selectedKeysChangeCallback(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(props.selectedKeys as string[], props.dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(props.selectedKeys as string[], props.dataIndex)}
                    size="small"
                >
                    Отфильтровать
                </Button>
                <Button
                    onClick={() => props.clearFilters && handleReset(props.clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Сбросить
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        props.close();
                    }}
                >
                    Закрыть
                </Button>
            </Space>
        </div>
    );
};
