import { createElement, FC, useState, MouseEvent, useEffect } from 'react';
import { Layout, Menu, theme, Button, MenuProps, Breadcrumb, Typography, Space, notification } from 'antd';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import {
    LaptopOutlined,
    PlusOutlined,
    ImportOutlined,
    SyncOutlined,
    LogoutOutlined,
    CloseCircleFilled,
} from '@ant-design/icons';
import './default.scss';
import { bankController } from '@/controllers/bank.controller';
import { observer } from 'mobx-react-lite';
import { bankStore } from '@/store/bank';
import { userController } from '@/controllers/user.controller';
import { breadcrumbStore } from '@/store/breadcrumb';
import { importController } from '@/controllers/import.controller';
import { importStore } from '@/store/import';
import { State } from '@/types/api/import/incom';

const { Header, Content, Sider } = Layout;

const SideMenu: FC = observer(() => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const { bankId } = useParams();

    const sideMenu: MenuProps['items'] = [
        {
            key: `bank_list`,
            icon: createElement(LaptopOutlined),
            label: `Банки`,
            children: (() => {
                const withImportStatuses = bankStore.bankList.map(bank => {
                    const isError = importStore.statusList.some(
                        el => el.bankId === bank.id && el.state === State.ERROR,
                    );
                    const isInProgress = importStore.statusList.some(
                        el => el.bankId === bank.id && el.state === State.PROGRESS,
                    );

                    return { ...bank, isError, isInProgress };
                });

                const result = withImportStatuses.map(bank => ({
                    key: `bank_${bank.id}`,
                    label: (
                        <Link to={`/${bank.id}/clients`}>
                            <Space>
                                {bank.name}
                                {bank.isInProgress && (
                                    <SyncOutlined
                                        spin
                                        style={{ color: '#faad14' }}
                                    />
                                )}
                                {bank.isError && <CloseCircleFilled style={{ color: '#ff4d4f' }} />}
                            </Space>
                        </Link>
                    ),
                }));
                result.push({
                    key: `add_bank`,
                    label: (
                        <Link
                            to="/add"
                            style={{ color: '#1677ff' }}
                        >
                            Добавить <PlusOutlined />
                        </Link>
                    ),
                });
                return result;
            })(),
        },
        {
            key: `templates`,
            icon: <ImportOutlined />,
            label: <Link to="/templates">Шаблоны импорта</Link>,
        },
    ];

    return (
        <Sider
            width={300}
            style={{ background: colorBgContainer }}
        >
            <Menu
                mode="inline"
                defaultSelectedKeys={[`bank_${bankId || 0}`]}
                defaultOpenKeys={['bank_list']}
                style={{ maxHeight: '100%', borderRight: 0 }}
                items={sideMenu}
            />
        </Sider>
    );
});

const Breadcrumbs: FC = observer(() => {
    const navigateTo = useNavigate();

    const onLinkClicked = (e: MouseEvent, to: string) => {
        e.preventDefault();
        navigateTo(to);
    };

    return (
        <Breadcrumb style={{ margin: '16px 0' }}>
            {breadcrumbStore.breadrumbs.map((brc, i) => (
                <Breadcrumb.Item key={brc.text + brc.to || '' + i}>
                    {brc.to ? (
                        <Typography.Link
                            href={brc.to}
                            onClick={e => onLinkClicked(e, brc.to || '')}
                        >
                            {brc.text}
                        </Typography.Link>
                    ) : (
                        brc.text
                    )}
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
});

export const DefaultLayout: FC = observer(() => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [logoutPending, updateLogoutPending] = useState(false);

    const [api, contextHolder] = notification.useNotification();

    const logout = async () => {
        updateLogoutPending(true);
        await userController.logout();
        updateLogoutPending(false);
    };

    return (
        <Layout className="default-layout">
            {contextHolder}
            <Header className="header">
                <div className="logo"></div>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={['2']}
                    items={[]}
                />
                <div
                    className=""
                    style={{ textAlign: 'right' }}
                >
                    <Button
                        loading={logoutPending}
                        onClick={() => logout()}
                    >
                        <LogoutOutlined /> Выход
                    </Button>
                </div>
            </Header>
            <Layout>
                <>
                    <SideMenu />
                    <Layout style={{ padding: '0 24px 24px', margin: '24px 0 0 0 ' }}>
                        <Breadcrumbs />
                        <Content
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                                background: colorBgContainer,
                                maxWidth: 2000,
                            }}
                        >
                            <Outlet />
                        </Content>
                    </Layout>
                </>
            </Layout>
        </Layout>
    );
});

export const defaultLayoutLoader = async () => {
    return await Promise.all([bankController.list(), importController.list()]);
};
