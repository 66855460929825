export const queryParser = (query: { [key: string | number]: string | number | Array<string | number> }) => {
    const result = Object.entries(query)
        .reduce((acc: string[], [key, value]) => {
            if (!value || (Array.isArray(value) && !value.length)) return acc;
            if (Array.isArray(value)) {
                acc.push(...(value?.map((currentValue: string | number) => `${key}[]=${currentValue}`) || []));
            } else acc.push(`${key}=${value}`);
            return acc;
        }, [])
        .join('&');

    return result ? `?${result}` : '';
};
