import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/index.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { IndexPage, indexPageLoader } from '@/pages/index-page';
import { BankPage, bankPageLoader } from '@/pages/bank-page';
import { ErrorPage } from '@/pages/error-page';
import { DefaultLayout, defaultLayoutLoader } from '@/layouts/default';
import { LoginPage } from '@/pages/login-page';
import { RequireAuth } from '@/components/hoc/RequireAuth';

import { BankClientsPage, bankClientsPageLoader } from '@/pages/bank-clients-page';
import { AddBankPage, addBankPageLoader } from '@/pages/add-bank-page';
import { AddClientPage, addClientPageLoader } from '@/pages/add-client-page';
import { EditClientPage, editClientPageLoader } from '@/pages/edit-client-page';
import { TemplatesPage, templatesPageLoader } from '@/pages/templates-page';
import { userController } from '@/controllers/user.controller';

import { ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';

// MOBX DEBUG
import { injectStores } from '@mobx-devtools/tools';
import { bankStore } from '@/store/bank';
import { userStore } from '@/store/user';
injectStores({
    userStore,
    bankStore,
});

userController.getTokenFromStorage();

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <RequireAuth>
                <DefaultLayout />
            </RequireAuth>
        ),
        loader: defaultLayoutLoader,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <IndexPage />,
                loader: indexPageLoader,
            },
            {
                path: ':bankId/',
                element: <BankPage />,
                loader: bankPageLoader,
                children: [
                    {
                        path: 'clients/',
                        element: <BankClientsPage />,
                        loader: bankClientsPageLoader,
                    },
                ],
            },
            {
                path: '/add',
                element: <AddBankPage />,
                loader: addBankPageLoader,
            },
            {
                path: ':bankId/clients/add',
                element: <AddClientPage />,
                loader: addClientPageLoader,
            },
            {
                path: ':bankId/clients/:clientId',
                element: <EditClientPage />,
                loader: editClientPageLoader,
            },
            {
                path: '/templates',
                element: <TemplatesPage />,
                loader: templatesPageLoader,
            },
        ],
    },
    {
        path: '/login',
        element: <LoginPage />,
        errorElement: <ErrorPage />,
    },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <ConfigProvider locale={ruRU}>
            <RouterProvider router={router} />
        </ConfigProvider>
    </React.StrictMode>,
);
