import { makeAutoObservable } from 'mobx';

export interface Breadrumb {
    text: string;
    to?: string;
}

class BareadcrumbStore {
    breadrumbs: Breadrumb[] = [];
    constructor() {
        makeAutoObservable(this);
    }

    set(data: Breadrumb[]) {
        this.breadrumbs = data;
    }
}

export const breadcrumbStore = new BareadcrumbStore();
