import { makeAutoObservable } from 'mobx';
import { ImportStatus } from '@/types/api/import/incom';

class ImportStore {
    statusList: ImportStatus[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    updateStatusList(statusList: ImportStatus[]) {
        this.statusList = statusList;
    }
}

export const importStore = new ImportStore();
