import './login-page.scss';
import { useLocation, Navigate } from 'react-router-dom';
import { LoginForm } from '@/components/forms/login-form';
import { observer } from 'mobx-react-lite';
import { userStore } from '@/store/user';

export const LoginPage = observer(() => {
    const location = useLocation();

    const fromPage = location.state?.from?.pathname || '/';

    if (userStore.isLoggedIn) return <Navigate to={fromPage} />;

    return (
        <div className="login-page">
            <div className="login-page__inner">
                <LoginForm />
            </div>
        </div>
    );
});
