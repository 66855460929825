import { importService } from '@/services/import.service';
import { importStore } from '@/store/import';
import { FetchStatus } from '@/types/FetchStatus';
import { BankId } from '@/types/api/banks/request-parameters';

class ImportController {
    async one(bankId: BankId): Promise<FetchStatus> {
        try {
            const result = await importService.one(bankId);
            const array = importStore.statusList.filter(el => el.bankId !== bankId);

            importStore.updateStatusList([...array.filter(el => el.bankId !== result[0].bankId), result[0]]);

            return {
                ok: true,
                error: '',
            };
        } catch (error) {
            return {
                ok: false,
                error: (error as Error) || 'Import status fetching failed',
            };
        }
    }

    async list(): Promise<FetchStatus> {
        try {
            const result = await importService.list();

            importStore.updateStatusList(result);

            return {
                ok: true,
                error: '',
            };
        } catch (error) {
            return {
                ok: false,
                error: (error as Error) || 'Import status fetching failed',
            };
        }
    }
}

export const importController = new ImportController();
