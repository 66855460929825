export enum State {
    PROGRESS = 'in_progress',
    COMPLETE = 'complete',
    ERROR = 'error',
    MANUAL = 'manual',
}

export interface ImportStatus {
    bankId: number;
    state: State;
    itemsProcessed: number;
    itemsTotal: number;
}
