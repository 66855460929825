import { FC, useState, useEffect, Suspense } from 'react';
import { Tabs } from 'antd';
import {
    useNavigate,
    useParams,
    Outlet,
    useLoaderData,
    LoaderFunction,
    useLocation,
    defer,
    Await,
} from 'react-router-dom';
import { BankSettings } from '@/components/pages/bank-page/bank-settings';
import { bankService } from '@/services/bank.service';
import { BankId } from '@/types/api/banks/request-parameters';
import { Bank, BankTemplate } from '@/types/api/banks/incom';
import { templateService } from '@/services/template.service';
import { CLoader } from '@/components/kit/c-loader';
import { breadcrumbStore } from '@/store/breadcrumb';

const Inner: FC<{ templates: BankTemplate[]; bank: Bank }> = ({ templates, bank }) => {
    const { bankId } = useParams();

    const navigateTo = useNavigate();

    const location = useLocation();

    const getActiveTab = () => {
        return /.*\/clients/.test(location.pathname) ? `clients_${bankId}` : 'settings';
    };

    const [currentTab, updateCurrentTab] = useState(getActiveTab());

    const onChange = (key: string) => {
        navigateTo(key === 'settings' ? `/${bankId}` : `/${bankId}/clients`);
    };

    // eslint-disable-next-line
    useEffect(() => updateCurrentTab(getActiveTab()), [location]);

    useEffect(() => {
        if (currentTab === 'settings') {
            breadcrumbStore.set([{ text: 'Главная', to: '/' }, { text: bank.name }]);
        } else {
            breadcrumbStore.set([
                { text: 'Главная', to: '/' },
                { text: bank.name, to: `/${bank.id}` },
                { text: 'Клиенты банка' },
            ]);
        }
    }, [currentTab]);

    return (
        <Tabs
            activeKey={currentTab}
            onChange={onChange}
            items={[
                {
                    label: `Клиенты`,
                    key: `clients_${bank.id}`,
                    children: <Outlet context={{ bank: bank, templates: templates }} />,
                },
                {
                    label: `Настройки`,
                    key: 'settings',
                    children: (
                        <BankSettings
                            bank={bank}
                            templates={templates}
                            key={bank.id}
                        />
                    ),
                },
            ]}
        />
    );
};

export const BankPage: FC = () => {
    const { data } = useLoaderData() as { data: [BankTemplate[], Bank] };

    return (
        <Suspense fallback={<CLoader />}>
            <Await resolve={data}>
                {([templates, bank]) => (
                    <Inner
                        templates={templates}
                        bank={bank}
                    />
                )}
            </Await>
        </Suspense>
    );
};

export const bankPageLoader: LoaderFunction = async ({ params }) => {
    const id: BankId = +(params?.bankId || 0);
    return defer({
        data: Promise.all([templateService.list(), bankService.one(id)]),
    });
};
