import { bankService } from '@/services/bank.service';
import { bankStore } from '@/store/bank';
import { Bank } from '@/types/api/banks/incom';
import { BankForm } from '@/types/api/banks/request-body';
import { FetchStatus } from '@/types/FetchStatus';

class BankController {
    async list(): Promise<FetchStatus> {
        try {
            const result = await bankService.list();
            bankStore.updateBankList(result);

            return {
                ok: true,
                error: '',
            };
        } catch (error) {
            return {
                ok: false,
                error: (error as Error) || 'Bank list fetching failed',
            };
        }
    }

    async add(bankForm: BankForm): Promise<Bank> {
        const result = await bankService.add(bankForm);
        await this.list();
        return result;
    }

    async update(bankForm: BankForm): Promise<Bank> {
        const result = await bankService.update(bankForm);
        await this.list();
        return result;
    }
}

export const bankController = new BankController();
