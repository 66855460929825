import { downloadFileFromExtUrl } from '@/helpers/api/download-file';
import { BankTemplate, Field } from '@/types/api/banks/incom';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, notification, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FC } from 'react';

export const TemplatesTable: FC<{ templates: BankTemplate[] }> = props => {
    type TableRow = {
        [key: string]: string | number | Array<Field>;
    };

    const [api, contextHolder] = notification.useNotification();

    const tableData = (data: Array<BankTemplate>): Array<TableRow> => {
        return data.map(item => {
            return { key: `key-${item.id}`, ...item };
        });
    };

    const columns = (): ColumnsType<TableRow> => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'template-id',
                width: 150,
            },
            {
                title: 'Название шаблона',
                dataIndex: 'name',
                key: 'template-name',
            },
            {
                title: 'Пример XLSX',
                dataIndex: 'sample',
                key: 'xlsx-example',
                width: 280,
                render: (_, record) => (
                    <Button
                        size="small"
                        onClick={() => {
                            // @ts-ignore
                            downloadTemplate({ file: record.sample, name: record.name });
                        }}
                    >
                        <DownloadOutlined />
                    </Button>
                ),
            },
        ];
    };

    const downloadTemplate = async (data: { file: string; name: string }) => {
        try {
            downloadFileFromExtUrl(data);
        } catch (e: any) {
            api.error({
                message: 'Ошибка',
                description: e?.toString() || e,
            });
        }
    };

    return (
        <>
            {contextHolder}
            <Table
                className="bank-clients-table"
                dataSource={tableData(props.templates)}
                columns={columns()}
                pagination={false}
            />
        </>
    );
};
