import { userController } from '@/controllers/user.controller';
import { LoginForm as LoginFormResult } from '@/types/api/user/request-body';
import { Button, Checkbox, Form, Input, Card, notification } from 'antd';
import { useState, FC } from 'react';
import { LoginOutlined } from '@ant-design/icons';

export const LoginForm: FC = () => {
    const [pending, updatePending] = useState<boolean>();
    const [api, contextHolder] = notification.useNotification();

    const openNotification = (message: string, description: string) => {
        api.error({
            message,
            description,
        });
    };

    const onFinish = async (values: LoginFormResult) => {
        updatePending(true);
        const result = await userController.login(values);
        updatePending(false);
        if (!result.ok) openNotification('Ошибка', result.error.toString() || '');
    };

    return (
        <>
            {contextHolder}
            <Card title="Вход в систему">
                <Form
                    name="login"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Логин"
                        name="name"
                        rules={[{ required: true, message: 'Введите логин!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Пароль"
                        name="password"
                        rules={[{ required: true, message: 'Введите пароль!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                    >
                        <Checkbox>Оставаться в системе</Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={pending}
                        >
                            <LoginOutlined /> Вход
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
};
