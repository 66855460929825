import { useLocation, Navigate } from 'react-router-dom';
import { userStore } from '@/store/user';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export const RequireAuth: FC<{ children: JSX.Element }> = observer(props => {
    const location = useLocation();

    if (!userStore.isLoggedIn) {
        return (
            <Navigate
                to="./login"
                state={{ from: location }}
            />
        );
    } else return props?.children || <></>;
});
