import './bank-clients-table.scss';
import { FC, useState } from 'react';
import { Table, Button, Space, Typography, Popconfirm } from 'antd';
import { Field } from '@/types/api/banks/incom';
import { Client } from '@/types/api/clients/incom';
import { BankClientsTableColumnOptions } from './bank-clients-table-column-options';
import { SortDirection } from '@/types/shared/Sort';
import type { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

export interface TableFIlters {
    limit: number;
    offset: number;
    query: string;
    queryFieldNum: number | null;
    sort: null | string | number;
    sortDirection: SortDirection;
}

export const tableFIltersPlaceholder: TableFIlters = {
    limit: 10,
    offset: 0,
    query: '',
    queryFieldNum: null,
    sort: null,
    sortDirection: '',
};

export const BankClientsTable: FC<{
    fields: Field[];
    clients: Client[];
    itemRemoveCallback: (clientId: number) => Promise<boolean>;
    itemEditCallback: (clientId: number) => any;
    itemAddCallback: () => any;
    itemImportCallback: () => any;
    itemListRemoveCallback: (idList: number[]) => Promise<boolean>;
    filterUpdateCallback: (filters: TableFIlters) => void;
    filters: TableFIlters;
    total: number;
}> = props => {
    const { Text } = Typography;

    type TableRow = {
        [key: string]: string | number | boolean;
    };

    const [selectedItems, setSelectedItems] = useState<Array<number>>([]);

    const [removedItemsIdList, setRemovedItemsIdList] = useState<Set<number>>(new Set());

    const [pending, setPending] = useState(false);

    const fields = [...props.fields].sort((a, b) => a.sort - b.sort);

    const getDataSource = (data: Client[]): Array<TableRow> => {
        return data.map(client => {
            const base = {
                key: client.id,
                id: client.id,
            };

            const data = client.values.reduce(
                (acc, current: string, index) => ({ ...acc, [fields[index].id]: current }),
                {},
            );

            return { ...base, ...data, disabled: removedItemsIdList.has(client.id) };
        });
    };

    const removeItem = async (id: number) => {
        setPending(true);
        const result = await props.itemRemoveCallback(id);
        if (result) {
            setRemovedItemsIdList(new Set([...Array.from(removedItemsIdList.values()), id]));
            setSelectedItems(selectedItems.filter(elId => elId !== id));
        }
        setPending(false);
    };

    const removeSelected = async () => {
        setPending(true);
        const result = await props.itemListRemoveCallback(selectedItems);
        if (result) {
            setRemovedItemsIdList(new Set([...Array.from(removedItemsIdList.values()), ...selectedItems]));
            setSelectedItems([]);
        }
        setPending(false);
    };

    const handleSort = (sort: number, sortDirection: SortDirection) => {
        props.filterUpdateCallback({ ...props.filters, sort, sortDirection });
    };

    const handleFilter = (sort: number, query: string) => {
        props.filterUpdateCallback({
            ...tableFIltersPlaceholder,
            ...props.filters,
            sort: props.filters.sort,
            sortDirection: props.filters.sortDirection,
            queryFieldNum: sort,
            query,
        });
    };

    const handlePagination = (page: number, pageSize: number) => {
        setRemovedItemsIdList(new Set());
        setSelectedItems([]);
        props.filterUpdateCallback({
            ...tableFIltersPlaceholder,
            ...props.filters,
            sort: props.filters.sort,
            sortDirection: props.filters.sortDirection,
            offset: (page - 1) * pageSize,
            limit: pageSize,
        });
    };

    const columns = () => {
        const columns: ColumnsType<TableRow> = fields.map(item => {
            const sortDirection = item.sort === props.filters.sort ? props.filters.sortDirection : '';
            const isFiltered = props.filters.queryFieldNum === item.sort && !!props.filters.query.length;

            return BankClientsTableColumnOptions(item, sortDirection, handleSort, handleFilter, isFiltered, isFiltered ? props.filters.query : '');
        });

        columns.push({
            title: 'Действия',
            key: 'action',
            width: 100,
            render: (_, record) => (
                <Space>
                    <Button
                        disabled={!!record.disabled}
                        size="small"
                        type="primary"
                        title="Редактировать"
                        onClick={() => props.itemEditCallback(+record.id)}
                    >
                        <EditOutlined />
                    </Button>

                    <Button
                        disabled={!!record.disabled}
                        size="small"
                        type="primary"
                        danger
                        onClick={() => {
                            removeItem(+record.id);
                        }}
                        title="Удалить"
                    >
                        <DeleteOutlined />
                    </Button>
                </Space>
            ),
        });

        return columns;
    };

    return (
        <Space
            direction="vertical"
            style={{ width: '100%' }}
        >
            <Space>
                <Text>Всего: {props.total},</Text>

                <Text>Выбрано {selectedItems.length}</Text>
                <Popconfirm
                    title="Удалить выбранных клиентов?"
                    onConfirm={removeSelected}
                    okText="Да"
                    cancelText="Нет"
                >
                    <Button
                        danger
                        disabled={!selectedItems.length}
                        size="small"
                    >
                        Удалить
                        <DeleteOutlined />
                    </Button>
                </Popconfirm>
                <Button
                    type="primary"
                    size="small"
                    onClick={() => props.itemAddCallback()}
                >
                    Создать
                    <PlusOutlined />
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={() => props.itemImportCallback()}
                >
                    Импортировать
                    <UploadOutlined />
                </Button>
            </Space>

            <Table
                className="bank-clients-table"
                dataSource={getDataSource(props.clients)}
                columns={columns()}
                loading={pending}
                scroll={{
                    x: 'max-content',
                }}
                rowSelection={{
                    selectedRowKeys: selectedItems,
                    type: 'checkbox',
                    onChange(selectedRowKeys, selectedRows) {
                        setSelectedItems(selectedRows.map(el => +el.id));
                    },
                    getCheckboxProps: (record: any) => ({
                        disabled: record.disabled,
                    }),
                }}
                pagination={{
                    current: props.filters.offset / props.filters.limit + 1,
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                    pageSize: props.filters.limit,
                    total: props.total,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    onChange: handlePagination,
                }}
            ></Table>
        </Space>
    );
};
