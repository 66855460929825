import { gFetch } from '@/helpers/api/g-fetch';
import { User } from '@/types/api/user/incom';
import { LoginForm } from '@/types/api/user/request-body';
import { userStore } from '@/store/user';

class UserService {
    async login(credentails: LoginForm) {
        const result = await gFetch<User>('main', 'login', {
            fetchConfig: {
                method: 'POST',
                body: JSON.stringify(credentails),
            },
        });

        return result;
    }

    async logout() {
        if (!userStore.token) return;
        return await gFetch<User>('main', 'logout', {
            fetchConfig: {
                method: 'POST',
            },
        });
    }
}

export const userService = new UserService();
