import { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { FileProtectOutlined, CaretUpFilled, CaretDownFilled } from '@ant-design/icons';
import { Field } from '@/types/api/banks/incom';
import { SortDirection } from '@/types/shared/Sort';

export const BankClientsTableColumn: FC<{ data: Field; sortDirection: SortDirection }> = props => {
    const { Text } = Typography;

    return (
        <Row
            align="middle"
            wrap={false}
        >
            <Col className='bank-clients-table__name'>{props.data.name}</Col>
            <Col
                className="bank-clients-table__check-order"
                flex="1 1 auto"
            >
                {!!props.data.checkOrder && (
                    <Text type="secondary">
                        <FileProtectOutlined /> {props.data.checkOrder}
                    </Text>
                )}
            </Col>
            <Col className={`bank-clients-table__sort bank-clients-table__sort--${props.sortDirection}`}>
                <div className="bank-clients-table__sort-arrow">
                    <CaretUpFilled />
                </div>
                <div className="bank-clients-table__sort-arrow">
                    <CaretDownFilled />
                </div>
            </Col>
        </Row>
    );
};
