import { useRef, useState } from 'react';
import type { ColumnType } from 'antd/es/table';
import type { InputRef } from 'antd';
import { Field } from '@/types/api/banks/incom';
import { BankClientsTableFilterDropdown } from './bank-clients-table-filter-dropdown';
import { BankClientsTableColumn } from './bank-clients-table-column';
import { SortDirection } from '@/types/shared/Sort';
import { Typography } from 'antd';
import { FilterFilled } from '@ant-design/icons';

export const BankClientsTableColumnOptions = (
    data: Field,
    sortDirection: SortDirection,
    handleSort: (sort: number, direction: SortDirection) => void,
    handleFilter: (sort: number, query: string) => void,
    filtered: boolean,
    filteredValue?: string,
) => {
    const searchInput = useRef<InputRef>(null);

    const handleHeaderCellClick = () => {
        let result: SortDirection = '';

        if (sortDirection === '') result = 'asc';
        else if (sortDirection === 'asc') result = 'desc';

        handleSort(data.sort, result);
    };

    const getColumnSearchProps = (): ColumnType<{
        [key: string]: any;
    }> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, close }) => (
            <BankClientsTableFilterDropdown
                selectedKeysChangeCallback={setSelectedKeys}
                selectedKeys={selectedKeys}
                clearFilters={clearFilters}
                close={close}
                dataIndex={data.sort}
                inputRef={searchInput}
                handleFilter={handleFilter}
            />
        ),
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) setTimeout(() => searchInput.current?.select(), 100);
        },
        filterIcon: () => <FilterFilled style={{ color: filtered ? '#1677ff' : 'inherit' }} />,
        filteredValue: filteredValue ? [filteredValue] : null,
    });

    return {
        ...getColumnSearchProps(),
        dataIndex: data.id,
        key: `key-${data.id}`,
        title: () => {
            return (
                <BankClientsTableColumn
                    data={data}
                    sortDirection={sortDirection}
                />
            );
        },
        onHeaderCell: () => {
            return {
                onClick: () => {
                    handleHeaderCellClick();
                },
            };
        },
        render: (text: string, record: any) => {
            return <Typography.Text delete={record.disabled}>{text}</Typography.Text>;
        },
    };
};
