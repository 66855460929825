export const MAIN_SERVICE_ROUTES = {
    bankWithoutParams: 'banks', // GET, POST
    bank: 'banks/%bankId%', // GET, PUT
    clients: 'banks/%bankId%/clients', // GET, POST, DELETE
    client: 'banks/%bankId%/clients/%clientId%', // GET, PUT, DELETE
    clientFile: 'banks/%bankId%/clients/upload', // GET
    login: 'login', // POST
    logout: 'logout', // POST
    templates: 'templates', // GET
    importStatus: 'import/status', // GET
};
