import { BankTemplate } from '@/types/api/banks/incom';
import { RetweetOutlined, CopyOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Form, Input, Button, Select, notification, Space } from 'antd';
import { BankForm as BankCreatingForm } from '@/types/api/banks/request-body';
import { TemplateView } from '../views/template-view';
import { Link } from 'react-router-dom';

export const BankForm: FC<{
    initalValues: Partial<BankCreatingForm>;
    templates: BankTemplate[];
    submitCallback: (form: BankCreatingForm) => any;
    pending: boolean;
}> = props => {
    const [newAuthToken, setNewAuthToken] = useState('');

    const [selectedTemplate, setSelectedTemplate] = useState(
        props.templates.find(template => template.id === props.initalValues.templateId),
    );

    const [api, contextHolder] = notification.useNotification();

    const copyToken = () => {
        navigator.clipboard.writeText(newAuthToken || props.initalValues.authToken || '');

        api.info({
            message: 'Токен авторизации скопирован в буфер обмена',
        });
    };

    const updateToken = () => {
        setNewAuthToken(uuidv4());
    };

    const onFinish = (value: BankCreatingForm) => {
        props.submitCallback({
            ...value,
            authToken: newAuthToken || props.initalValues.authToken || '',
            id: value?.id || 0,
        });
    };

    return (
        <>
            {contextHolder}
            <Form
                layout="vertical"
                name="bank"
                onFinish={value => onFinish(value)}
                // onFinishFailed={onFinishFailed}

                initialValues={{
                    ...props.initalValues,
                    // @ts-ignore
                    id: props.initalValues?.id || null,
                }}
            >
                {props.initalValues.id ? (
                    <Form.Item
                        name="id"
                        label="Id банка"
                    >
                        <Input
                            readOnly
                            disabled
                        />
                    </Form.Item>
                ) : (
                    <></>
                )}

                <Form.Item
                    name="name"
                    label="Название банка"
                    rules={[{ required: true, message: 'Введите название банка' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Токен авторизации"
                    required
                >
                    <div
                        className=""
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr auto auto',
                            gridColumnGap: '8px',
                        }}
                    >
                        <Form.Item
                            rules={[{ required: true, message: 'Введите токен авторизации' }]}
                            noStyle
                        >
                            <Input
                                readOnly
                                value={newAuthToken || props.initalValues.authToken}
                            />
                        </Form.Item>
                        <Button onClick={() => copyToken()}>
                            Скопировать <CopyOutlined />
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={() => updateToken()}
                        >
                            Пересоздать <RetweetOutlined />
                        </Button>
                    </div>
                </Form.Item>

                <Form.Item
                    label="Шаблон импорта данных"
                    required
                >
                    <Form.Item
                        name="templateId"
                        rules={[{ required: true, message: 'Выберите шаблон' }]}
                        noStyle
                    >
                        <Select
                            onChange={id => setSelectedTemplate(props.templates.find(template => template.id === id))}
                        >
                            {props.templates.map((template: BankTemplate) => (
                                <Select.Option
                                    value={template.id}
                                    title={template.description}
                                    key={`bank_template_option_${template.id}`}
                                >
                                    {template.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <TemplateView template={selectedTemplate || null}></TemplateView>
                </Form.Item>

                <Form.Item>
                    <Space>
                        <Link to="/">
                            <Button>Отмена</Button>
                        </Link>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={props.pending}
                        >
                            Сохранить
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </>
    );
};
