import { userService } from '@/services/user.service';
import { userStore } from '@/store/user';
import { LoginForm } from '@/types/api/user/request-body';
import { USER_TOKEN } from '@/constants/storage';
import { FetchStatus } from '@/types/FetchStatus';

class UserController {
    async login(loginForm: LoginForm): Promise<FetchStatus> {
        try {
            const result = await userService.login(loginForm);

            userStore.login(result.token);

            loginForm.remember
                ? window.localStorage.setItem(USER_TOKEN, result.token)
                : window.sessionStorage.setItem(USER_TOKEN, result.token);

            return {
                ok: true,
                error: '',
            };
        } catch (error) {
            console.error(error);
            return {
                ok: false,
                error: error as Error,
            };
        }
    }

    async logout(): Promise<FetchStatus> {
        try {
            try {
                await userService.logout();
            } catch (e) {}

            userStore.logout();

            window.localStorage.removeItem(USER_TOKEN);
            window.sessionStorage.removeItem(USER_TOKEN);

            return {
                ok: true,
                error: '',
            };
        } catch (error) {
            return {
                ok: false,
                error: error as Error,
            };
        }
    }

    getTokenFromStorage() {
        const token = window.localStorage.getItem(USER_TOKEN) || window.sessionStorage.getItem(USER_TOKEN);
        if (token && token.length) userStore.login(token);
    }
}

export const userController = new UserController();
