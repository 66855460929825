import { FC, Suspense, useEffect, useState } from 'react';
import { useLoaderData, LoaderFunction, defer, Await, useLocation, useNavigate } from 'react-router-dom';
import { Bank } from '@/types/api/banks/incom';
import { BankId } from '@/types/api/banks/request-parameters';
import { ClientForm as ClientAddForm } from '@/types/api/clients/request-body';
import { Client } from '@/types/api/clients/incom';
import { ClientId } from '@/types/api/clients/request-parameters';
import { bankService } from '@/services/bank.service';
import { clientService } from '@/services/client.service';
import { CLoader } from '@/components/kit/c-loader';
import { ClientForm } from '@/components/forms/client-form';
import { notification } from 'antd';
import { breadcrumbStore } from '@/store/breadcrumb';

export const EditClientPage: FC = () => {
    const { bank, client, bankId, clientId } = useLoaderData() as {
        bank: Bank;
        client: Client;
        bankId: BankId;
        clientId: ClientId;
    };

    const [api, contextHolder] = notification.useNotification();
    const [formPending, setFormPending] = useState(false);

    const location = useLocation();

    const navigateTo = useNavigate();

    const fromPage = location.state?.pathname + location.state?.search || '/';

    const onSubmit = async (data: ClientAddForm) => {
        setFormPending(true);

        try {
            await clientService.update(bankId, clientId, data);
            api.success({
                message: 'Данные клиента изменены',
            });
            navigateTo(fromPage);
        } catch (e: any) {
            api.error({
                message: 'Ошибка',
                description: e?.toString() || e,
            });
        }

        setFormPending(false);
    };

    useEffect(() => {
        breadcrumbStore.set([
            { text: 'Главная', to: '/' },
            { text: bank.name, to: `/${bank.id}` },
            { text: 'Клиенты банка', to: `/${bank.id}/clients` },
            { text: `Редактировать клиента (${client.id})` },
        ]);
        // eslint-disable-next-line
    }, []);

    return (
        <Suspense fallback={<CLoader />}>
            <Await resolve={bank}>
                {() => (
                    <>
                        {contextHolder}
                        <ClientForm
                            fields={bank.template.fields}
                            values={client.values}
                            submitCallback={onSubmit}
                            pending={formPending}
                        />
                    </>
                )}
            </Await>
        </Suspense>
    );
};

export const editClientPageLoader: LoaderFunction = async ({ params }) => {
    const bankId: BankId = +(params?.bankId || 0);
    const clientId: ClientId = +(params?.clientId || 0);

    return defer({
        bank: await bankService.one(bankId),
        client: await clientService.one(bankId, clientId),
        bankId,
        clientId,
    });
};
