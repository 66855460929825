import { FC } from 'react';
import { Spin } from 'antd';
import './c-loader.scss';

export const CLoader: FC<{ tip?: string }> = ({ tip }) => {
    return (
        <div className="c-loader">
            <Spin tip={tip || ''} />
        </div>
    );
};
