import { FC, Suspense, useEffect } from 'react';
import { useLoaderData, LoaderFunction, defer, Await } from 'react-router-dom';
import { Bank, BankTemplate } from '@/types/api/banks/incom';
import { templateService } from '@/services/template.service';
import { CLoader } from '@/components/kit/c-loader';
import { AddBank } from '@/components/pages/bank-page/add-bank';
import { breadcrumbStore } from '@/store/breadcrumb';

export const AddBankPage: FC = () => {
    const { data } = useLoaderData() as { data: [Bank, BankTemplate[]] };

    useEffect(() => {
        breadcrumbStore.set([{ text: 'Главная', to: '/' }, { text: 'Добавить банк' }]);
    }, []);

    return (
        <Suspense fallback={<CLoader />}>
            <Await resolve={data}>{templates => <AddBank templates={templates} />}</Await>
        </Suspense>
    );
};

export const addBankPageLoader: LoaderFunction = async () => {
    return defer({
        data: await templateService.list(),
    });
};
