import { FC } from 'react';
import { Form, Input, Button } from 'antd';
import { ClientForm as ClientAddForm } from '@/types/api/clients/request-body';
import { Field } from '@/types/api/banks/incom';

export const ClientForm: FC<{
    fields: Field[];
    values?: ClientAddForm;
    submitCallback: (form: ClientAddForm) => any;
    pending: boolean;
}> = props => {
    const fields = [...props.fields].sort((a, b) => a.sort - b.sort);

    const onFinish = (value: Record<string, string>) => {
        props.submitCallback(Object.values(value));
    };

    const initalValues = (): Record<string, string | null> => {
        return fields.reduce(
            (acc: Record<string, string | null>, v: Field, currentIndex: number) => ({
                ...acc,
                [`name-${v.id}`]: props.values?.[currentIndex] || null,
            }),
            {},
        );
    };

    return (
        <Form
            className="client-form"
            layout="vertical"
            name="client"
            onFinish={value => onFinish(value)}
            initialValues={initalValues()}
        >
            {fields.map(el => (
                <Form.Item
                    className="client-form__item"
                    key={`key-${el.id}`}
                    name={`name-${el.id}`}
                    label={el.name}
                    rules={[{ required: !!el.checkOrder, message: 'Заполните поле' }]}
                >
                    <Input />
                </Form.Item>
            ))}

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={props.pending}
                >
                    Сохранить
                </Button>
            </Form.Item>
        </Form>
    );
};
