import { gFetch } from '@/helpers/api/g-fetch';
import { Bank } from '@/types/api/banks/incom';
import { BankForm } from '@/types/api/banks/request-body';
import { BankId } from '@/types/api/banks/request-parameters';

class BankService {
    async one(bankId: BankId) {
        return await gFetch<Bank>('main', 'bank', {
            params: { bankId },
        });
    }

    async list() {
        return await gFetch<Bank[]>('main', 'bankWithoutParams');
    }

    async add(body: BankForm) {
        return await gFetch<Bank>('main', 'bankWithoutParams', {
            fetchConfig: {
                method: 'POST',
                body: JSON.stringify(body),
            },
        });
    }

    async update(body: BankForm) {
        return await gFetch<Bank>('main', 'bank', {
            fetchConfig: {
                method: 'PUT',
                body: JSON.stringify(body),
            },
            params: { bankId: body.id! },
        });
    }
}

export const bankService = new BankService();
