import { BankTemplate } from '@/types/api/banks/incom';
import { FC, useState } from 'react';

import { BankForm } from '@/components/forms/bank-form';
import { BankForm as BankCratingForm } from '@/types/api/banks/request-body';
import { bankController } from '@/controllers/bank.controller';
import { notification } from 'antd';
import { bankPlaceholder } from '@/placeholders/bankPlaceholder';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { importController } from '@/controllers/import.controller';

export const AddBank: FC<{ templates: BankTemplate[] }> = props => {
    const [api, contextHolder] = notification.useNotification();
    const navigateTo = useNavigate();

    const onSubmit = async (data: BankCratingForm) => {
        setFormPending(true);

        try {
            await bankController.add(data);
            api.success({
                message: 'Банк добавлен',
            });
            navigateTo('/');
            importController.list();
        } catch (e: any) {
            api.error({
                message: 'Ошибка',
                description: e?.toString() || e,
            });
        }

        setFormPending(false);
    };

    const [formPending, setFormPending] = useState(false);

    return (
        <>
            {contextHolder}
            <BankForm
                initalValues={{
                    id: bankPlaceholder.id,
                    name: bankPlaceholder.name,
                    authToken: uuidv4(),
                }}
                templates={props.templates}
                submitCallback={onSubmit}
                pending={formPending}
            />
        </>
    );
};
