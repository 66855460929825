import { gFetch } from '@/helpers/api/g-fetch';
import { Client } from '@/types/api/clients/incom';
import { BankId } from '@/types/api/banks/request-parameters';
import { ClientId, ListFilter } from '@/types/api/clients/request-parameters';
import { ClientForm } from '@/types/api/clients/request-body';
import { apiQueryList } from '@/helpers/api/get-route';
import { WithPagination } from '@/types/IncomWrapper';

class ClientService {
    async one(bankId: BankId, clientId: ClientId) {
        return await gFetch<Client>('main', 'client', {
            params: { bankId, clientId },
        });
    }

    async update(bankId: BankId, clientId: ClientId, body: ClientForm) {
        return await gFetch<Client>('main', 'client', {
            fetchConfig: {
                method: 'PUT',
                body: JSON.stringify(body),
            },
            params: { bankId, clientId },
        });
    }

    async deleteOne(bankId: BankId, clientId: ClientId) {
        return await gFetch('main', 'client', {
            fetchConfig: {
                method: 'DELETE',
            },
            params: { bankId, clientId },
        });
    }

    async list(bankId: BankId, filter: ListFilter | string) {
        return await gFetch<WithPagination<Client[]>>('main', 'clients', {
            params: { bankId },
            query: (filter || {}) as apiQueryList,
        });
    }

    async add(bankId: BankId, body: ClientForm) {
        return await gFetch<Client>('main', 'clients', {
            fetchConfig: {
                method: 'POST',
                body: JSON.stringify(body),
            },
            params: { bankId },
        });
    }

    async deleteList(bankId: BankId) {
        return await gFetch('main', 'clients', {
            fetchConfig: {
                method: 'DELETE',
            },
            params: { bankId },
        });
    }

    async upload(bankId: BankId, file: File) {
        const form = new FormData();

        form.append('file', file);

        return await gFetch<Client[]>('main', 'clientFile', {
            fetchConfig: {
                // @ts-ignore
                body: form,
                method: 'POST',
            },
            params: { bankId },
        });
    }
}

export const clientService = new ClientService();
