import { FC, Suspense, useState, useEffect } from 'react';
import {
    Await,
    LoaderFunction,
    defer,
    useLoaderData,
    useLocation,
    useNavigate,
    useOutletContext,
    useSearchParams,
} from 'react-router-dom';
import { clientService } from '@/services/client.service';
import { Client } from '@/types/api/clients/incom';
import { Bank, BankTemplate } from '@/types/api/banks/incom';
import {
    BankClientsTable,
    TableFIlters,
    tableFIltersPlaceholder,
} from '@/components/pages/bank-page/bank-clients-table/bank-clients-table';
import { notification, Modal, Form, Typography, Spin } from 'antd';
import { CLoader } from '@/components/kit/c-loader';
import { InboxOutlined, SyncOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { RcFile } from 'antd/es/upload';
import { queryParser } from '@/helpers/api/query-parser';
import { urlSearchParamsToObject } from '@/helpers/api/url-search-params-to-object';
import { ExceptionOutlined } from '@ant-design/icons';
import { WithPagination } from '@/types/IncomWrapper';
import { importController } from '@/controllers/import.controller';
import { importStore } from '@/store/import';
import { State } from '@/types/api/import/incom';
import { observer } from 'mobx-react-lite';

export const BankClientsPage: FC = observer(() => {
    const [api, contextHolder] = notification.useNotification();
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const outletContext = useOutletContext<{ bank: Bank; templates: BankTemplate[] }>();

    const { data } = useLoaderData() as { data: WithPagination<Client[]> };

    const navigateTo = useNavigate();

    const location = useLocation();

    const [searchParams] = useSearchParams();

    const [filters, setFilters] = useState<TableFIlters>({
        ...tableFIltersPlaceholder,
        ...urlSearchParamsToObject(searchParams, ['limit', 'offset', 'queryFieldNum', 'sort']),
    });

    const bankImportState = importStore.statusList.find(el => el.bankId === outletContext.bank.id)?.state;

    let scheduledPending: NodeJS.Timer | null;

    let latestImportState: State | null | undefined;

    const removeBankClient = async (clientId: number): Promise<boolean> => {
        try {
            await clientService.deleteOne(outletContext.bank.id, clientId);

            api.success({
                message: 'Клиент удален',
            });
            return true;
        } catch (e: any) {
            api.error({
                message: 'Не удалось удалить клиента',
                description: e?.toString() || e,
            });
            return false;
        }
    };

    const editBankClient = (id: number) => {
        navigateTo(location.pathname + '/' + id, {
            state: location,
        });
        setIsUpdating(true);
    };

    const addBankClient = () => {
        navigateTo(location.pathname + '/add', {
            state: location,
        });
        setIsUpdating(true);
    };

    const removeSelectedClients = async (clientIdList: number[]): Promise<boolean> => {
        try {
            await Promise.all(clientIdList.map(id => clientService.deleteOne(outletContext.bank.id, id)));
            api.success({
                message: 'Клиенты удалены',
            });
            navigateTo(location.pathname + queryParser(filters as any));
            setIsUpdating(true);
            return true;
        } catch (e: any) {
            api.error({
                message: 'Не удалось удалить клиентов',
                description: e?.toString() || e,
            });

            return false;
        }
    };

    const fetchItemsByFilter = async (filters: TableFIlters) => {
        setFilters(filters);
        navigateTo(location.pathname + queryParser(filters as any));
        setIsUpdating(true);
    };

    const importBankClients = async (file: RcFile): Promise<string> => {
        setIsFileUploading(true);

        try {
            await clientService.upload(outletContext.bank.id, file);

            api.success({
                message: 'Файл загружен',
            });

            setIsInProgress(true);

            importController.one(outletContext.bank.id);
        } catch (e: any) {
            api.error({
                message: 'Ошибка',
                description: e?.toString() || e,
            });
        }

        setIsFileUploading(false);
        setIsImportModalOpen(false);

        return '';
    };

    const startPending = () => {
        scheduledPending = setInterval(() => {
            importController.one(outletContext.bank.id);
        }, 5000);
    };

    const stopPending = () => {
        if (scheduledPending) clearInterval(scheduledPending);
        scheduledPending = null;
    };

    useEffect(() => {
        if (latestImportState !== bankImportState) {
            latestImportState = bankImportState;

            if (bankImportState === State.ERROR) {
                api.error({ message: 'Во время последней загрузки файла произошла ошибка' });
            }

            if (bankImportState === State.PROGRESS) {
                if (!scheduledPending) startPending();
            } else {
                if (isInProgress) {
                    navigateTo(location.pathname + queryParser(filters as any));
                    setIsUpdating(true);
                    stopPending();
                }

                setIsInProgress(false);
            }
        }

        return () => {
            stopPending();
        };
    }, [bankImportState]);

    const indicator = (
        <SyncOutlined
            spin
            style={{ color: '#faad14' }}
        />
    );

    return (
        <>
            {contextHolder}
            <Spin
                tip={isInProgress ? 'Идет обработка данных' : 'Идет обновление данных'}
                spinning={isInProgress || isUpdating}
                indicator={indicator}
            >
                <Suspense fallback={<CLoader />}>
                    <Await resolve={data}>
                        {data => {
                            useEffect(() => {
                                setIsUpdating(false);
                            }, [data]);

                            return (
                                <BankClientsTable
                                    fields={outletContext.bank.template.fields}
                                    clients={data.result}
                                    itemRemoveCallback={removeBankClient}
                                    itemEditCallback={editBankClient}
                                    itemAddCallback={addBankClient}
                                    itemImportCallback={() => setIsImportModalOpen(true)}
                                    itemListRemoveCallback={removeSelectedClients}
                                    filterUpdateCallback={fetchItemsByFilter}
                                    filters={filters}
                                    total={data.total}
                                />
                            );
                        }}
                    </Await>
                </Suspense>
            </Spin>
            <Modal
                title="Импорт клиентов"
                open={isImportModalOpen}
                footer={<></>}
                centered
                closable={!isFileUploading}
                maskClosable={false}
                onCancel={() => setIsImportModalOpen(false)}
            >
                <Form
                    labelCol={{ span: 0 }}
                    wrapperCol={{ span: 24 }}
                >
                    {isFileUploading ? (
                        <CLoader tip="Загрузка файла" />
                    ) : (
                        <Form.Item valuePropName="fileList">
                            <p>
                                <Typography.Text type="warning">
                                    <ExceptionOutlined /> Внимание! Предыдущие записи будут удалены!
                                </Typography.Text>
                            </p>
                            <Dragger
                                multiple={false}
                                accept=".xlsx"
                                action={importBankClients}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>

                                <p>
                                    <Typography.Text>Нажмите или перетащите файл для загрузки</Typography.Text>
                                </p>
                                <p>
                                    {' '}
                                    <Typography.Text type="secondary">Поддерживаемый формат: xlsx</Typography.Text>
                                </p>
                            </Dragger>
                        </Form.Item>
                    )}
                </Form>
            </Modal>
        </>
    );
});

export const bankClientsPageLoader: LoaderFunction = async ({ params, request }) => {
    const bankId = +(params.bankId || 0);

    await importController.one(bankId);

    return defer({
        data: clientService.list(bankId, new URL(request.url).search),
    });
};
