import { ApiServiceList } from '@/types/ApiService';
import { domainParser } from '@/helpers/api/domain-parser';
import { DOMAINS } from './domains';
import { MAIN_SERVICE_ROUTES } from '@/constants/api/endpoints/main';
import { MOCK_SERVICE_ROUTES } from '@/constants/api/endpoints/mock';

// @ts-ignore
const currentDomain = DOMAINS[process.env.REACT_APP_ENV];

export const API: ApiServiceList = {
    main: {
        domain: `${domainParser(currentDomain.main)}`,
        routes: MAIN_SERVICE_ROUTES,
    },
    mock: {
        domain: domainParser(currentDomain.mock, false),
        routes: MOCK_SERVICE_ROUTES,
    },
};
