import { gFetch } from '@/helpers/api/g-fetch';
import { BankTemplate } from '@/types/api/banks/incom';

class TemplateService {
    async list() {
        return await gFetch<BankTemplate[]>('main', 'templates');
    }
}

export const templateService = new TemplateService();
