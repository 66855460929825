import { gFetch } from '@/helpers/api/g-fetch';
import { ImportStatus } from '@/types/api/import/incom';
import { BankId } from '@/types/api/banks/request-parameters';

class ImportService {
    async one(bankId: BankId) {
        return await gFetch<ImportStatus[]>('main', 'importStatus', {
            query: {
                bankId,
            },
        });
    }

    async list() {
        return await gFetch<ImportStatus[]>('main', 'importStatus');
    }
}

export const importService = new ImportService();
