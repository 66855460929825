export const downloadFileFromExtUrl = (data: { file: string; name: string }): void => {
    if (!data.file) throw new Error('Not found path to file');

    const extension = data.file.split('.').pop();

    fetch(data.file)
        .then(res => res.blob())
        .then(blob => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            document.body.appendChild(link);
            link.setAttribute('download', data.name + '.' + extension);
            link.href = url;
            link.click();
            document.body.removeChild(link);
        });
};
