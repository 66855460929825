import { breadcrumbStore } from '@/store/breadcrumb';
import { LoaderFunction } from 'react-router-dom';

export const IndexPage = () => {
    return <></>;
};

export const indexPageLoader: LoaderFunction = async () => {
    breadcrumbStore.set([{ text: 'Главная' }]);
    return null;
};
