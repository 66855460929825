import { Bank, BankTemplate } from '@/types/api/banks/incom';
import { FC, useState } from 'react';

import { BankForm } from '@/components/forms/bank-form';
import { BankForm as BankCreatingForm } from '@/types/api/banks/request-body';
import { notification, Modal } from 'antd';

import { ExceptionOutlined } from '@ant-design/icons';
import { bankController } from '@/controllers/bank.controller';
import { clientService } from '@/services/client.service';

export const BankSettings: FC<{ bank: Bank; templates: BankTemplate[] }> = props => {
    const [api, contextHolder] = notification.useNotification();
    const [templateId, setTemplateId] = useState(props.bank.template.id || 0);

    const onSubmit = (data: BankCreatingForm) => {
        (data.templateId !== templateId
            ? new Promise((resolve, reject) => {
                  Modal.confirm({
                      title: 'Уверены, что хотите сменить шаблон импорта?',
                      icon: <ExceptionOutlined />,
                      content: `После смены шаблона все клиенты банка "${props.bank.name}" будут удалены.`,
                      okText: 'Продолжить',
                      cancelText: 'Отмена',
                      async onOk() {
                          await clientService.deleteList(props.bank.id);
                          setTemplateId(data.templateId);
                          resolve(null);
                      },
                      onCancel() {
                          reject(null);
                      },
                  });
              })
            : Promise.resolve()
        )
            .then(async () => {
                updateFormPending(true);

                try {
                    await bankController.update(data);
                    api.success({
                        message: 'Данные банка обновлены',
                    });
                } catch (e: any) {
                    api.error({
                        message: 'Ошибка',
                        description: e?.toString() || e,
                    });
                }

                updateFormPending(false);
            })
            .catch(e => {});
    };

    const [formPending, updateFormPending] = useState(false);
    return (
        <>
            {contextHolder}
            <BankForm
                initalValues={{
                    id: props.bank.id,
                    name: props.bank.name,
                    templateId: templateId,
                    authToken: props.bank.authToken,
                }}
                templates={props.templates}
                submitCallback={onSubmit}
                pending={formPending}
            />
        </>
    );
};
