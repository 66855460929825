import { makeAutoObservable } from 'mobx';

class User {
    token: string = '';
    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn() {
        return !!this.token;
    }

    login(token: string) {
        this.token = token;
    }

    logout() {
        this.token = '';
    }
}

export const userStore = new User();
