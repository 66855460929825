import { BankTemplate } from '@/types/api/banks/incom';
import { Table, Tooltip, Space } from 'antd';
import { FC } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FieldType } from '@/types/api/banks/incom';

export const TemplateView: FC<{ template: BankTemplate | null }> = props => {
    const columns = [
        {
            title: 'Поле',
            dataIndex: 'fieldName',
            key: 'name',
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            key: 'type',
            render: (data: FieldType) => {
                return (
                    <Tooltip
                        title={data.description}
                        trigger="click"
                    >
                        <Space
                            align="center"
                            style={{ cursor: 'pointer' }}
                        >
                            <div>{data.name}</div>
                            <InfoCircleOutlined />
                        </Space>
                    </Tooltip>
                );
            },
        },
        {
            title: 'Сортировка',
            dataIndex: 'order',
            key: 'order',
        },
    ];

    if (props.template)
        return (
            <Table
                pagination={false}
                dataSource={props.template.fields
                    .sort((a, b) => a.sort - b.sort)
                    .map(field => {
                        return {
                            fieldName: field.name,
                            type: field.type,
                            order: field.checkOrder,
                            key: `template_field_${field.id}`,
                        };
                    })}
                columns={columns}
            />
        );

    return <></>;
};
