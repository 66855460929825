import { FC, Suspense, useEffect, useState } from 'react';
import { useLoaderData, LoaderFunction, defer, Await, useLocation, useNavigate } from 'react-router-dom';
import { Bank } from '@/types/api/banks/incom';
import { BankId } from '@/types/api/banks/request-parameters';
import { ClientForm as ClientAddForm } from '@/types/api/clients/request-body';
import { bankService } from '@/services/bank.service';
import { clientService } from '@/services/client.service';
import { CLoader } from '@/components/kit/c-loader';
import { ClientForm } from '@/components/forms/client-form';
import { notification } from 'antd';
import { breadcrumbStore } from '@/store/breadcrumb';

export const AddClientPage: FC = () => {
    const { data, id } = useLoaderData() as { data: Bank; id: BankId };
    const [api, contextHolder] = notification.useNotification();
    const [formPending, setFormPending] = useState(false);

    const location = useLocation();

    const navigateTo = useNavigate();

    const fromPage = location.state?.pathname + location.state?.search || '/';

    const onSubmit = async (data: ClientAddForm) => {
        setFormPending(true);

        try {
            await clientService.add(id, data);
            api.success({
                message: '',
                description: 'Клиент добавлен',
            });
            navigateTo(fromPage);
        } catch (e: any) {
            api.error({
                message: 'Ошибка',
                description: e?.toString() || e,
            });
        }

        setFormPending(false);
    };

    useEffect(() => {
        breadcrumbStore.set([
            { text: 'Главная', to: '/' },
            { text: data.name, to: `/${data.id}` },
            { text: 'Клиенты банка', to: `/${data.id}/clients` },
            { text: 'Добавить клиента' },
        ]);
        // eslint-disable-next-line
    }, []);

    return (
        <Suspense fallback={<CLoader />}>
            <Await resolve={data}>
                {() => (
                    <div>
                        {contextHolder}
                        <ClientForm
                            fields={data.template.fields}
                            submitCallback={onSubmit}
                            pending={formPending}
                        />
                    </div>
                )}
            </Await>
        </Suspense>
    );
};

export const addClientPageLoader: LoaderFunction = async ({ params }) => {
    const id: BankId = +(params?.bankId || 0);

    return defer({
        data: await bankService.one(id),
        id,
    });
};
