import { makeAutoObservable } from 'mobx';
import { Bank } from '@/types/api/banks/incom';

class BankStore {
    bankList: Bank[] = [];
    constructor() {
        makeAutoObservable(this);
    }

    updateBankList(bankList: Bank[]) {
        this.bankList = bankList;
    }
}


export const bankStore = new BankStore();
